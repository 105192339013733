@import "normalize";

$color-text-default: #333;

$color-cat1-dark: rgb(27,158,119);
$color-cat2-dark: rgb(217,95,2);
$color-cat3-dark: rgb(117,112,179);
$color-cat4-dark: rgb(231,41,138);
$color-cat5-dark: rgb(102,166,30);

$color-cat1-light: rgb(102,194,165);
$color-cat2-light: rgb(252,141,98);
$color-cat3-light: rgb(141,160,203);
$color-cat4-light: rgb(231,138,195);
$color-cat5-light: rgb(166,216,84);

html {
	font-family: 'Nunito Sans', sans-serif;
	line-height: 1.3;
}

body {
	padding: 0 10px;
	color: $color-text-default;
}

a,
a:visited {
	color: $color-cat5-dark;
}

a:hover {
	color: $color-cat5-light;
}

h1,
h2 {
	color: $color-cat2-dark;
}

h3 {
	color: $color-cat3-dark;
}


.layout-constrained-width {
	max-width: 50rem;
	margin: 0 auto;
}

.layout-major-section {
	margin: 2em 0;
}


.social-links {
	list-style: none;
	padding-left: 0;
	color: $color-cat4-light;
}

.social-link > svg {
	display: inline-block;
	width: 1em;
	height: 1em;
	margin-right: .3em;
	vertical-align: -2px;
	color: #999;
}


.topic-list-title {
	font-weight: bold;
	color: #555;
}


.resume-item {
	margin: 1em 0;
}

.resume-item-heading,
.resume-role {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: baseline;
}

.resume-role,
.resume-publication-authors {
	color: $color-cat3-light;
}

.resume-item-heading {
	color: $color-cat3-dark;

	h3 {
		margin: 0;
	}
}

.resume-experience > li {
	list-style-type: none;
}

.resume-experience > li::before {
	content: "‣";
	display: inline-block;
	width: 1em;
	margin-left: -1em;
	font-size: 1.7rem;
	line-height: 1rem;
	vertical-align: -4px;
	color: #999;
}


.resume-item-project {
	display: grid;
	grid-column-gap: 10px;
	grid-template-rows: auto 1fr;

	&.project-even {
		grid-template-areas:
			"image header"
			"image body";
	}

	&.project-odd {
		grid-template-areas:
			"header image"
			"body image";
	}

	@media only screen and (max-width: 425px) {
		display: block;
	}
}

.project-image {
	display: block;
	grid-area: image;
	width: 191px;
}

.project-image > img {
	max-width: 100%;
}

.project-name {
	grid-area: header;
	margin: 0;

	@media only screen and (max-width: 425px) {
		margin: 1em 0;
	}
}

.project-name a,
.project-name a:hover,
.project-name a:visited {
	color: $color-cat3-dark;
	color: unset;
}

.project-body {
	grid-area: body;
}


.figure-fullwidth,
.figure-fullwidth img {
	width: 100%;
	margin: 0;
}

.figure-fullwidth figcaption {
	font-style: italic;
	font-size: .85em;
}

.figure-link {
	display: block;
}
